<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建体系落地检查题库</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        有软件平台还需要有合理的题库
        <br />
        好的检查题目可以帮助你的企业的体系进步
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        每年维持审核员的审核经验和知识水平很困难
        <br />
        检查内容年年一样走过场
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        利用专门训练过的AI大模型进行有效的题库转换，提高效率
        <br />
        专业训练过的人员查漏补缺，确保检查题目的有效性
        <br />
        编制针对性的体系检查表
        <br />
        软件平台题库内容的定期维护更新
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>